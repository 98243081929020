.introPreviewSide {
    grid-column: a;
}

.previewSideTitle {
    color: var(--black-color);
    font-family: var(--title-font);
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
}

@media (max-width: 719px) {
    .previewSideTitle {
        margin-top: calc(3* 8px);
    }
}

.previewSideText {
    margin-top: 0;
    color: var(--black-color);
    font-family: var(--text-font);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

@media (max-width: 719px) {
    .previewSideText {
        margin-top: calc(.5* 8px);
    }
}

@media (max-width: 719px) {
    .btnLink {
        margin-top: calc(3* 8px);
    }
}