.contactSection {
    padding: 20px 100px;
    min-height: 100vh;
    background-image: url('../../static/bg_imgContact.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.contactSection::before {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
}

.SectionContent {
    width: 100%;
}

.wrapperText {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contentTitle {
    color: var(--white-color);
    font-family: var(--title-font);
    font-style: normal;
    font-weight: 600;
    font-size: 65px;
    margin-bottom: 50px;
}

.contentTextW {
    max-width: 400px;
    width: 100%;
}

.contentText {
    color: var(--white-color);
    font-family: var(--text-font);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.1;
}

.contentLink {
    color: var(--white-color);
    font-family: var(--text-font);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    transition: var(--main-transition);
    text-decoration: underline;
}

.contentLink:hover {
    color: var(--grey-color);
}

.linkTitle {
    margin-bottom: 14px;
}

.wrapperLinks {
    color: var(--white-color);
    font-family: var(--title-font);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
}

.emailTitle {
    margin-bottom: 3px;
}

@media screen and (max-width: 56.25em) {
    .contactSection {
        padding: 20px 25px;
    }
    .linkTitle {
        margin-bottom: 5px;
    }
    .contentTitle {
        margin-bottom: 15px;
    }
    .wrapperText {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .contentText {
        margin-bottom: 27px;
    }
    .linksMargin {
        margin-bottom: 55px;
    }
    .contentTextW {
        margin-bottom: 15px;
    }
}