.homeContent {
    flex: 1 auto;
    display: flex;
    flex-wrap: wrap;
}

.homeContent > * {
    flex-basis: 100%;
}

.introSection {
    z-index: 2;
}

@media screen and (max-width: 1439px) {
    .introSection {
        margin-bottom: 40px;
    }
}

.introContent {
    display: grid;
    grid-column-gap: calc(4* 8px);
}

@media screen and (max-width: 719px) {
    .introContent {
        padding-left: 0;
        padding-right: 0;
        grid-template-columns: calc(calc(4* 8px) - calc(4* 8px)) 1fr 1fr 1fr calc(calc(4* 8px) - calc(4* 8px));
        grid-template-rows: -webkit-min-content;
        grid-template-rows: min-content;
        grid-template-areas:
        '. a a a .'
        '. b b b .'
        '. c c c .'
        '. d d d .'
        '. e e e .';
    }
}

@media screen and (min-width: 1025px) and (max-width: 1439px), (min-width: 720px) and (max-width: 1024px) {
    .introContent {
        grid-template-columns: 0 repeat(3, 1fr) 0;
        grid-template-rows: -webkit-min-content;
        grid-template-rows: min-content;
        grid-template-areas: '. a b c .';
    }
}

@media screen and (min-width: 1440px) {
    .introContent {
        display: grid;
        grid-template-columns: 1fr repeat(3, calc((1440px - 4* calc(4* 8px)) / 3)) 1fr;
        grid-template-columns: 1fr repeat(3, calc((1440px - 4* calc(4* 8px)) / 3)) 1fr;
        grid-template-rows: -webkit-min-content;
        grid-template-rows: min-content;
        grid-template-areas: '. a b c .';
    }
}

.introContent::before {
    grid-area: 1 / 1 / span 2 / b;
    background-color: var(--black-color);
    content: '\A';
    display: block;
    z-index: -1;
}

@media (max-width: 719px) {
    .introContent::before {
        grid-area: 1 / 1 / span 2 / span 3;
    }
}

@media (max-width: 520px) {
    .introContent::before {
        grid-column-end: -1;
    }
}

.introContent::after {
    grid-column: a / b;
    grid-row: 3;
    width: 100%;
    padding-top: calc(100%* 9 / 16 + 19* 8px);
    content: '\A';
    display: block;
}

@media (max-width: 719px) {
    .introContent::after {
        display: none;
    }
}

@media (max-width: 520px) {
    .introContent::after {
        display: none;
    }
}

.introTitle {
    grid-row: 1;
    grid-column: a / b;
    padding-top: calc(4* 8px);
    padding-right: calc(4* 8px);
    padding-bottom: calc(8* 6px);
    color: var(--white-color);
    font-family: var(--title-font);
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 46px;
}

@media screen and (max-width: 719px) {
    .introTitle {
        grid-area: a;
        padding-top: calc(5* 8px);
        padding-right: 0;
        font-size: 38px;
        line-height: 46px;
    }
}

@media screen and (max-width: 520px) {
    .introTitle {
        padding-bottom: calc(4* 8px);
        font-size: 26px;
        line-height: 31px;
    }
}

.introText {
    grid-column: a;
    grid-row: 2;
    color: var(--white-color);
    font-family: var(--text-font);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

@media screen and (max-width: 719px) {
    .introText {
        grid-area: b;
        grid-column-end: span 2;
        padding-right: calc(4* 8px);
        padding-bottom: calc(16* 8px);
    }
}

@media screen and (max-width: 520px) {
    .introText {
        grid-area: b;
        padding-right: 0;
        padding-bottom: calc(9* 8px);
    }
}

.introPlaceholder {
    grid-column: a / b;
    grid-row: 2;
    pointer-events: none;
    width: 100%;
    padding-top: calc(100%* 9 / 16 - 32px);
}

@media screen and (max-width: 719px) {
    .introPlaceholder {
        display: none;
    }
}

.previewSideImg {
    grid-column: b / c;
    grid-row: 2 / 4;
    display: flex;
    flex-direction: column;
}

.wrapperImgTitle {
    position: relative;
    position: -webkit-sticky;
    position: sticky;
    top: 115px;
    grid-column: b / c;
    grid-row: 2;
    width: 100%;
    height: auto;
}

@media (max-width: 719px) {
    .previewSideImg {
        grid-area: c;
        margin-top: calc(-11* 8px);
    }
}

@media (max-width: 520px) {
    .previewSideImg {
        grid-area: c;
        margin-top: calc(-5* 8px);
    }
}

.previewSideImgSource {
    /* position: -webkit-sticky;
    position: sticky;
    top: 115px;
    grid-column: b / c;
    grid-row: 2; */
    width: 100%;
    height: auto;
}

.previewSideImgSubtitle { 
    top: 10px;
    right: 20px;
    position: absolute;
    color: var(--white-color);
    font-family: var(--subtitle-font);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

@media screen and (max-width: 1439px) {
    .previewSideImgSubtitle {
        font-size: 14px;
    }
}

.sourceImgTop {
    /* top: 270px; */
}

@media (max-width: 719px) {
    .previewSideImgSource {
        position: relative;
        top: unset;
        grid-row: 1;
    }
    .sourceImgTop {
        top: unset;
    }
}

.galleryItemTitle {
    margin-top: calc(19* 8px);
}

.galleryItem1 {
    grid-row: 3 / 5;
}

@media (max-width: 719px) {
    .galleryItem1 {
        grid-area: d;
    }
}

/* about social links  */


/* community section  */

.communitySection {
    grid-template-areas: '. title image content .';
    z-index: 1;
    margin-top: calc(-4* 8px);
    display: grid;
}

@media (min-width: 720px) and (max-width: 890px) {
    .communitySection{
        grid-template-areas:
        ' . image image image image'
        'title title title . . '
        'content content content . . ';
        grid-template-columns: 0 repeat(3, 1fr) 0;
    }
}

@media (max-width: 719px) {
    .communitySection {
        grid-template-areas:
        ' . image image image image'
        'title title title title . '
        'content content content content . ';
        grid-template-columns: calc(calc(4* 8px) - calc(4* 8px)) 1fr 1fr 1fr calc(calc(4* 8px) - calc(4* 8px));
        grid-template-rows: -webkit-min-content;
        grid-template-rows: min-content;
    }
}

@media (min-width: 720px) and (max-width: 890px), (max-width: 719px) {
    .communitySection {
        padding-bottom: calc(3* 8px);
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (min-width: 1440px) {
    .communitySection {
        grid-template-columns: 1fr repeat(3, calc((1440px - 4* calc(4* 8px)) / 3)) 1fr;
        grid-template-rows: -webkit-min-content;
        grid-template-rows: min-content;
        grid-column-gap: calc(4* 8px);
    }
}

.communitySection::before {
    content: '\A';
    display: block;
    grid-area: 1 / 1 / image / image;
    background-color: var(--black-color);
    z-index: -1;
}

@media (max-width: 719px) {
    .communitySection::before {
        display: none;
    }
}

@media (max-width: 520px) {
    .communitySection::before {
        display: none;
    }
}

.communitySection::after {
    content: '\A';
    display: block;
    grid-area: 1 / -3 / auto / -1;
    z-index: -1;
}

.communitySectionTitle {
    color: var(--white-color);
    font-family: var(--title-font);
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    padding-bottom: calc(20* 8px);
    padding-top: calc(15* 8px);
    grid-area: title;
}

@media (min-width: 720px) and (max-width: 890px), (max-width: 719px) {
    .communitySectionTitle {
        padding-bottom: calc(3* 8px);
        padding-right: calc(4* 8px);
        padding-left: calc(4* 8px);
        background-color: var(--black-color);
        font-size: 38px;
        line-height: 46px;
        z-index: 20;
    }
}

@media (min-width: 720px) and (max-width: 890px) {
    .communitySectionTitle{
        margin-top: calc(-12* 8px);
        padding-top: calc(5* 8px);
    }
}

@media (max-width: 520px) {
    .communitySectionTitle {
        margin-right: 0;
        margin-top: calc(-7* 8px);
        padding-top: calc(3* 8px);
        font-size: 26px;
        line-height: 31px;
    }
}

.communitySectionImg {
    grid-area: image;
    width: 100%;
    height: auto;
    padding-bottom: calc(20* 8px);
    padding-top: calc(15* 8px);
}

@media (min-width: 720px) and (max-width: 890px), (max-width: 719px) {
    .communitySectionImg {
        padding-top: 0;
        padding-bottom: 0;
    }
}

@media (max-width: 520px) {
    .communitySectionImg {
        grid-column-start: 1;
    }
}

.communitySectionContent {
    grid-area: content;
    padding-bottom: calc(20* 8px);
    padding-top: calc(15* 8px);
}

@media (min-width: 720px) and (max-width: 890px), (max-width: 719px) {
    .communitySectionContent {
        padding-bottom: calc(8* 8px);
        padding-top: 0;
        padding-right: 8px;
        padding-left: calc(2 * 8px);;
        background-color: var(--black-color);
    }
}

@media (max-width: 520px) {
    .communitySectionContent {
        margin-right: 0;
        padding-top: 0;
    }
}

.communitySectionContentText {
    color: var(--black-color);
    font-family: var(--text-font);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

@media (min-width: 720px) and (max-width: 890px), (max-width: 719px) {
    .communitySectionContentText {
        color: var(--white-color);
    }
    .previewBtn {
        margin-bottom: 20px !important;
    }
}

@media (min-width: 720px) and (max-width: 890px), (max-width: 719px) {
    .linkBtn {
        color: var(--white-color) !important;
        border-color: var(--white-color) !important;
    }
    .linkBtn:hover {
        color: var(--black-color) !important;
        background-color: var(--white-color) !important;
    }
}

.previewBtn {
    padding: 12px 65px !important;
    margin-bottom: 20px;
    font-size: 18px !important;
    color: var(--white-color) !important;
    border-color: var(--white-color) !important;
}

.previewBtn:hover {
    color: var(--grey-color) !important;
    border-color: var(--grey-color) !important;
}