@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Hand:wght@400..700&display=swap');

:root, body {
    --white-color: #fff;
    --header-color: rgba(255, 255, 255, 0.8);
    --grey-color: #7d7a7a;
    --black-color: #000;
    --title-font: "Roboto Condensed", sans-serif;
    --text-font: "Open Sans", sans-serif;
    --subtitle-font: "Edu AU VIC WA NT Hand", cursive; 
    --main-transition: .3s ease-in-out;
    --header-transition: .5s linear;
    --header-transition-word: .45s linear;
}

#root {
    display: flex;
    flex-direction: column;
}

ul, ol {
    list-style: none;
    text-decoration: none;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

* {
    box-sizing: border-box;
    position: relative;
    margin: 0;
    padding: 0;
    outline: none;
}

a {
    outline: none;
    text-decoration: none;
}

p {
    margin: 0;
}

body {
    font-family: var(--text-font);
    font-weight: 400;
    line-height: 1.1;
    font-style: normal;
    -webkit-font-kerning: normal;
    font-kerning: normal;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    /* font-feature-settings: "kern" 1, "liga" 1, "kern"; */
}

.main {
    display: flex;
    min-height: 100%;
    flex: 1 auto;
    flex-direction: column;
    /* padding-top: 100px; */
}