.linkTitle {
    margin-bottom: 14px;
}

.contentLink {
    color: var(--white-color);
    font-family: var(--text-font);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    transition: var(--main-transition);
    text-decoration: underline;
}

.contentLink:hover {
    color: var(--grey-color);
}

.wrapperLinks {
    color: var(--white-color);
    font-family: var(--title-font);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
}

.social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.socialLink {
    display: flex;
    justify-content: space-between;
    gap: 3px;
}

.socialAbout {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: flex-start;
}

@media screen and (max-width: 56.25em) {
    .linkTitle {
        margin-bottom: 5px;
    }
}