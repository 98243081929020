.galleryItem {
  margin-top: calc(15 * 8px);
  grid-column-gap: calc(4* 8px);
  display: grid;
}

.wrapperImg {
    display: flex;
    flex-direction: column;
    grid-column: b / c;
    grid-row: 2;
    width: 100%;
    height: auto;
    position: relative;
}

.imgSub {
    color: var(--black-color);
    font-family: var(--subtitle-font);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    position: absolute;
    top: 20px;
    right: 20px;
}

@media screen and (max-width: 1439px) {
    .imgSub {
        font-size: 14px;
    }
}

@media (min-width: 720px) and (max-width: 890px) {
    .galleryItem {
        margin-top: calc(8* 8px);
    }
}

@media (max-width: 719px) {
    .galleryItem {
        margin-top: calc(8* 8px);
        padding-left: 0;
        padding-right: 0;
        grid-template-columns: calc(calc(4* 8px) - calc(4* 8px)) 1fr 1fr 1fr calc(calc(4* 8px) - calc(4* 8px));
        grid-template-rows: -webkit-min-content;
        grid-template-rows: min-content;
        grid-template-areas:
        '. a a a .'
        '. b b b .'
        '. c c c .'
        '. d d d .'
        '. e e e .';
    }
}

@media (max-width: 520px) {
    .galleryItem {
        margin-top: calc(6* 8px);
    }
}

@media (min-width: 1025px) and (max-width: 1439px), (min-width: 720px) and (max-width: 1024px) {
    .galleryItem {
        grid-template-columns: 0 repeat(3, 1fr) 0;
        grid-template-rows: -webkit-min-content;
        grid-template-rows: min-content;
        grid-template-areas: '. a b c .';
    }
}

@media screen and (min-width: 1440px) {
    .galleryItem {
        grid-template-columns: 1fr repeat(3, calc((1440px - 4 * calc(4 * 8px)) / 3)) 1fr;
        grid-template-rows: -webkit-min-content;
        grid-template-rows: min-content;
        grid-template-areas: ". a b c .";
    }
}

.galleryItem::before {
    grid-area: 1 / 1 / auto / 3;
    content: '\A';
    display: block;
    z-index: -1;
}

.galleryItem::after {
    grid-area: 1 / -3 / auto / -1;
    content: '\A';
    display: block;
    z-index: -1;
}

.galleryImg {
    grid-column: b / c;
    grid-row: 2;
    width: 100%;
    height: auto;
}

@media (max-width: 719px) {
    .galleryImg {
        grid-row: 1;
    }
}