.footer {
    flex: 0 auto;
    width: auto;
    left: 0;
    right: 0;
    pointer-events: none;
    display: grid;
    grid-column-gap: calc(4* 11px);
}

.footer::before {
    grid-area: 1 / 1 / auto / 3;
    content: '\A';
    display: block;
}

.footer::after {
    content: '\A';
    display: block;
    grid-area: 1 / -3 / auto / -1;
}

@media (max-width: 719px) {
    .footer {
        grid-template-columns: calc(calc(4* 8px) - calc(4* 8px)) 1fr 1fr 1fr calc(calc(4* 8px) - calc(4* 8px));
        grid-template-rows: -webkit-min-content;
        grid-template-rows: min-content;
        grid-template-areas:
        '. a a a .'
        '. b b b .'
        '. c c c .'
        '. d d d .'
        '. e e e .';
        grid-column-gap: calc(4* 8px);
    }
}

@media (min-width: 1025px) and (max-width: 1439px), (min-width: 720px) and (max-width: 1024px) {
    .footer {
        grid-template-columns: 0 repeat(3, 1fr) 0;
        grid-template-rows: -webkit-min-content;
        grid-template-rows: min-content;
        grid-template-areas: '. a b c .';
        grid-column-gap: calc(4* 7px);
    }
}

@media (min-width: 1440px) {
    .footer {
        grid-template-columns: 1fr repeat(3, calc((1440px - 4* calc(4* 8px)) / 3)) 1fr;
        grid-template-rows: -webkit-min-content;
        grid-template-rows: min-content;
        grid-template-areas: '. a b c .';
    }
}

.footerList {
    grid-area: a / a / c / c;
    flex-shrink: 0;
    z-index: 2;
    overflow: hidden;
    pointer-events: initial;
}

.footerListContent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: calc(-2* 8px);
    margin-top: calc(-1* 8px);
    padding-top: calc(2* 8px);
    padding-bottom: calc(2* 8px);
    padding-left: 0;
    padding-right: 0;
    list-style: none;
    font-family: var(--text-font);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
}

.listContentItem {
    white-space: nowrap;
    margin-top: calc(1* 8px);
}

.listContentItem:hover a {
    color: var(--grey-color);
}

.listContentItem > a {
    transition: var(--main-transition);
    color: var(--black-color);
}

.listContentItem::before {
    content: "·";
    display: block;
    float: left;
    width: calc(2* 8px);
    text-align: center;
    color: inherit;
}