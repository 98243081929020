.previewSideBtn {
    margin-top: calc(4* 8px);
    display: inline-block;
    border-color: var(--black-color);
    color: var(--black-color);
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    padding-top: 3px;
    padding-left: 11px;
    padding-right: 11px;
    padding-bottom: 2px;
    font-family: var(--text-font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    cursor: pointer;
    transition: var(--main-transition);
}

.previewSideBtn:hover {
    color: var(--white-color);
    background-color: var(--black-color);
}