.header {
    max-width: 100vw;
    width: 100%;
    padding: 20px 25px;
    z-index: 55;
}

.headerContent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.headerLogo {
    color: var(--black-color);
    font-family: var(--title-font);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
}

.headerNavigation {
    max-width: 400px;
    width: 100%;
}

.headerNavigationList {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navigationItem {
    color: var(--black-color);
    font-family: var(--title-font);
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    transition: var(--main-transition);
    display: flex;
    align-items: center;
}

.navigationItem::before {
    content: '';
    display: block;
    margin-right: 7px;
    width: 5px;
    height: 5px;
    background-color: var(--black-color);
    border-radius: 50%;
    transition: var(--main-transition);
    opacity: 0;
    pointer-events: none;
}

.navigationItem:hover::before {
    opacity: 1;
    pointer-events: initial;
}

.navigationItemActive::before {
    opacity: 1;
    pointer-events: initial;
}

.navigationItem:hover .ItemHoverEl {
    opacity: 1;
    pointer-events: initial;
}

.navigationItemActive .ItemHoverEl {
    opacity: 1;
    pointer-events: initial;
}

.ItemHoverEl {
    transition: var(--main-transition);
    opacity: 0;
    pointer-events: none;
}

.headerBurger {
    display: none;
}

.mobileNav {
    display: none;
}

.mobileHeaderLogo {
    display: none;
}

.desktopLogo {
    display: block;
}

.logoEl {
    display: none;
}

.headerLogo > h1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.logoSubtitle {
    color: var(--grey-color);
    font-family: var(--text-font);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}

@media screen and (max-width: 56.25em) {
    .headerLogo {
        font-size: 21px;
    }
    .mobileHeaderLogo {
        display: block;
    }
    .desktopLogo {
        display: none;
    }
    .header {
        padding: 20px 25px;
    }
    .headerNavigation {
        display: none;
    }
    .headerBurger {
        display: none;
        padding: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 1.755625rem;
        height: 1.755625rem;
        border: none;
        cursor: pointer;
        z-index: 5;
        background: 0 0;
    }
    .headerBurgerContent {
        position: relative;
        width: 22px;
        height: 18px;
        background-color: transparent;
    }
    .burgerContent {
        top: calc(50% - 0.0625rem);
      }
      .burgerContent1 {
        top: 0;
      }
      .burgerContent2 {
        bottom: 0;
      }
      .menuOpen {
        width: 0 !important;
      }
      .headerBurgerContent .burgerContent,
      .burgerContent1,
      .burgerContent2 {
        content: "";
        display: block;
        -webkit-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        right: 0;
        position: absolute;
        width: 22px;
        height: 2px;
        border-radius: 5px;
        background-color: var(--black-color);
      }
      .beforeOpen {
        top: calc(50% - 0.0375rem);
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      .afterOpen {
        bottom: calc(50% - 0.0625rem);
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .mobileNav {
        display: flex;
        width: 100vw;
        height: 130px;
        padding: 0 10px 20px 10px;
        background-color: var(--white-color);
        position: fixed;
        right: -100%;
        top: 95px;
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
    }
    .mobileNavList {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .navMenuOpen {
        right: 0;
    }
}

@media screen and (min-width: 768px) {
    .headerLogo > h1 {
        width: 100%;
        display: flex;
        /* justify-content: space-between;
        align-items: center; */
        -webkit-transition: var(--header-transition);
        transition: var(--header-transition);
    }
}

@media screen and (min-width: 768px) and (max-width:56.25em) {
    .mobileNav {
        width: 277px;
    }
        .headerLogo {
        /* width: 40px; */
        font-size: 15px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
    .headerLogo {
        /* width: 50px; */
        font-size: 12.5px;
    }
}

@media screen and (min-width:1440px) {
    .header {
        padding: 20px 100px;
    }
}

.hidden {
    overflow: hidden;
    -ms-touch-action: none;
    touch-action: none;
}