.JoinSection {
    padding: 20px 100px;
    min-height: 100vh;
    background-color: var(--black-color);
}

.sectionContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.sectionTitle {
    color: var(--white-color);
    font-family: var(--title-font);
    font-style: normal;
    font-weight: 600;
    font-size: 65px;
    margin-bottom: 10px;
}

.sectionSubtitle {
    color: var(--grey-color);
    font-family: var(--text-font);
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 20px;
    text-align: center;
}

.sectionText {
    color: var(--white-color);
    font-family: var(--text-font);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 18px;
    line-height: 1.1;
}

.sectionForm {
    max-width: 1100px;
    padding: 40px 120px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.wrapperInputs {
    width: 100%;
    gap: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.formInput {
    max-width: 500px;
    width: 100%;
    background-color: transparent;
    text-decoration: none;
    color: var(--white-color);
    font-family: var(--text-font);
    font-weight: 500;
    font-style: normal;
    padding: 10px 20px 10px 0;
    border: none;
    border-bottom: 1px solid var(--white-color);
}

.formInput::placeholder {
    color: var(--white-color);
}

.textArea {
    resize: none;
    max-width: 500px;
    width: 100%;
    height: auto;
    position: relative;
}

.wrapperTextarea {
    max-width: 500px;
    width: 100%;
    height: auto;
    position: relative;
}

.textLengths {
    position: absolute;
    right: 0;
    bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lengthElement {
    text-decoration: none;
    color: var(--white-color);
    font-family: var(--text-font);
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
}

.submitBtn {
    margin-top: calc(4* 8px);
    display: inline-block;
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    padding: 12px 65px;
    font-size: 18px;
    color: var(--white-color);
    border-color: var(--white-color);
    font-family: var(--text-font);
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    text-transform: uppercase;
    cursor: pointer;
    transition: var(--main-transition);
    background-color: transparent;
}

.submitBtn:hover {
    color: var(--grey-color);
    border-color: var(--grey-color);
}

@media screen and (max-width: 56.25em) {
    .JoinSection {
        padding: 20px 25px;
    }
    .sectionForm {
        padding: 0;
    }

    .sectionTitle {
        font-size: 30px;
        margin-bottom: 25px;
    }
    .sectionSubtitle {
        font-size: 17px;
        margin-bottom: 0;
    }
    .sectionText {
        font-size: 16px;
        margin-bottom: 35px;
    }
}